if (typeof Swup !== "undefined") {
    const swup = new Swup({
        containers: ['#layout_main', '#layout_header', '#layout_footer']
    });

    swup.on('clickLink', function () {
        $(".ssm-nav-visible").removeClass("ssm-nav-visible");
        $(".ssm-overlay").fadeOut(300);
        body.removeClass("body--no-scroll");
        if ($(".lib--dialog").length) {
            lui_dialog.close();
        }
    });

    document.addEventListener('swup:animationOutDone', () => {
        window.scrollTo(0, 0);
    });

    swup.on('contentReplaced', function () {
        swup.options.containers.forEach((selector) => {
            Object.keys(window.lui.layout).forEach(function(key) {
                if ($(key).length) {
                    window.lui.layout[key]("layout",key)
                }
            });
            Object.keys(window.lui.comp).forEach(function(key) {
                if ($(selector).find(key).length) {
                    window.lui.comp[key]("comp",key)
                }
            });
            Object.keys(window.lui.part).forEach(function(key) {
                if ($(selector).find(key).length) {
                    window.lui.part[key]("part",key)
                }
            });
            Object.keys(window.lui.lib).forEach(function(key) {
                let s = window.lui.lib[key].selector;
                if ($(selector).find(s).length) {
                    window.lui.lib[key](key,$(selector).find(s))
                }
            });
        });

        let anchor = $("[data-lib-anchor-id]");
        let hash = window.location.hash;

        if (anchor.length) {
            anchor.each(function(){
                let elm = $(this);

                if(hash && elm.data("lib-anchor-id") === hash.replace('#','')) {
                    elm.anchor_anim(0);
                }
            });
        }


        if (typeof fbq !== "undefined") {
            fbq('track', 'PageView');
        }

        if (typeof gtag !== "undefined") {
            let configs = [];
            window.dataLayer.forEach(function(config){
                if (config[0] === "config") {
                    if (typeof config[1] !== "undefined" && !configs.includes(config[1])) {
                        configs.push(config[1]);
                        gtag('config', config[1], {
                            'page_title': document.title,
                            'page_path': window.location.pathname + window.location.search
                        });
                    }
                }
            });
        }

        if (typeof window.dataLayer !== "undefined") {
            window.dataLayer.push({
                'event': 'VirtualPageview',
                'virtualPageURL': window.location.pathname + window.location.search,
                'virtualPageTitle': document.title
            });
        }

        if (typeof window.seznam_retargeting_id !== "undefined") {
            $.getScript("https://c.imedia.cz/js/retargeting.js");
        }

        if(typeof window.luiCookieConsentInit !== 'undefined') {
            window.luiCookieConsentInit()
        }

        document.body.classList.remove("body--preload");
        document.body.classList.add("body--loaded");
    });
}
